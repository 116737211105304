import React, { useState, useEffect } from "react";
import Spline from "@splinetool/react-spline";
import "./home.css";
import TelegramIcon from "../../assets/TelegramIcon.png";

function Home() {
  const [email, setEmail] = useState("");
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [placeholder, setPlaceholder] = useState("Enter your email");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // Track if the email is submitted

  useEffect(() => {
    // Check if "eft2048cch" is true in local storage
    const submitted = localStorage.getItem("eft2048cch");
    if (submitted === "true") {
      setIsSubmitted(true); // If true, set isSubmitted to true
    }
  }, []); // Run this effect only once on component mount

  const handleInputChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setInvalidEmail(false);

    if (value.trim() === "") {
      setPlaceholder("Enter your email");
    } else {
      setPlaceholder("");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setInvalidEmail(true);
      return;
    }

    // Store the email in local storage
    localStorage.setItem("eft2048cch", true);
    setIsSignedUp(true);
    setIsSubmitted(true); // Set isSubmitted to true after submitting the email
  };

  // Function to validate email
  const validateEmail = (email) => {
    // Regular expression for email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const Completionist = () => <span>The countdown has ended!</span>;

  return (
    <div className="home__container">
      <div className="home__main__elements">
        <div className="home__title__box">
          <div className="home__intro__box">
            <div className="home__intro__title">$HOOPS</div> Is the first way of
            hooping on-chain!
            <a href="https://t.me/HoopsOnchainBot" target="blank">
              <button className="home__button">
                Ball right into it!
                <img src={TelegramIcon} alt="HoopsOnChain" />
              </button>
            </a>
          </div>
          <div className="spline__scene">
            <Spline scene="https://prod.spline.design/p6eF8iiAAQyJhIYx/scene.splinecode" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

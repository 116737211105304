import React, { useEffect, useState } from "react";

import "./App.css";

import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import About from "./components/About/About";
import Minigame from "./components/Minigame/Minigame";
import Playoff from "./components/Playoff/Playoff";

import Loader from "./components/Loader/Loader";

function Main() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <div className="main">
        <Home />
        <About />
        {/* <Playoff /> */}
        <Minigame />
      </div>
      <Footer />
    </div>
  );
}

export default Main;

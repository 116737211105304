import React, { useState, useEffect } from "react";
import Spline from "@splinetool/react-spline";
import "./about.css";
import closeX from "../../assets/closeIcon.svg";

function About() {
  const [clickedBox, setClickedBox] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth <= 992);
    }

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleBoxClick = (boxNumber) => {
    if (!isSmallScreen) {
      setClickedBox(boxNumber);
    }
  };

  const renderContent = () => {
    switch (clickedBox) {
      case 1:
        return (
          <div className="expanded__about__box">
            <div className="expanded__about__top">
              <a onClick={() => handleBoxClick(null)}>
                <img src={closeX} alt="Go Back" className="close__button" />
              </a>
            </div>
            <div className="expanded__middle">
              <div className="expanded__about__left">
                <div className="left__title">
                  Hoops
                  <hr />
                </div>
                <div className="left__text">
                  Our courts will be exclusive for everyone holding a Hoops NFT.
                </div>
              </div>
              <div className="expanded__about__right">
                <Spline scene="https://prod.spline.design/UPqOU9rWZr2kEMVC/scene.splinecode" />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="expanded__about__box">
            <div className="expanded__about__top">
              <a onClick={() => handleBoxClick(null)}>
                <img src={closeX} alt="Go Back" className="close__button" />
              </a>
            </div>
            <div className="expanded__middle">
              <div className="expanded__about__left">
                <div className="left__title">
                  Community
                  <hr />
                </div>
                <div className="left__text">
                  We have events at most major crypto conferences. Find us at
                  ETH CC!
                </div>
              </div>
              <div className="expanded__about__right">
                <Spline scene="https://prod.spline.design/O0E5e0W3JTvuG8oN/scene.splinecode" />
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="expanded__about__box">
            <div className="expanded__about__top">
              <a onClick={() => handleBoxClick(null)}>
                <img src={closeX} alt="Go Back" className="close__button" />
              </a>
            </div>
            <div className="expanded__middle">
              <div className="expanded__about__left">
                <div className="left__title">
                  Liftoff
                  <hr />
                </div>
                <div className="left__text">
                  Don't wait for the shot clock to run out! <br />
                  Join our community and be the first to know!
                </div>
              </div>
              <div className="expanded__about__right">
                <Spline scene="https://prod.spline.design/eoPgd6uhVp1gNE5B/scene.splinecode" />
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="about__boxes">
            <div className="about__box" onClick={() => handleBoxClick(1)}>
              <div className="about__box__topper">
                <div className="about__box__title">Hoops</div>
              </div>
              <hr />
              <div className="about__box__inner">
                Hoops is building the next great basketball brand, and we're
                starting by buying a court.
              </div>
            </div>
            <div className="about__box" onClick={() => handleBoxClick(2)}>
              <div className="about__box__topper">
                <div className="about__box__title">Community</div>
              </div>
              <hr />
              <div className="about__box__inner">
                A membership based club with tournaments and leagues.
              </div>
            </div>
            <div className="about__box" onClick={() => handleBoxClick(3)}>
              <div className="about__box__topper">
                <div className="about__box__title">Liftoff</div>
              </div>
              <hr />
              <div className="about__box__inner">
                The memberships will be released soon and launch is set during
                the NBA Playoffs.
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="about__container">
      <div className="about__main__elements">
        <div className="about__title">About</div>
        {renderContent()}
      </div>
    </div>
  );
}

export default About;

import React, { useState, useEffect } from "react";
import Spline from "@splinetool/react-spline";
import "./minigame.css";

function Minigame() {
  return (
    <div className="minigame__container">
      <div className="minigame__main__elements">
        <div className="spline__dribble">
          <Spline scene="https://prod.spline.design/ZNbwKLUb5mOUTvzm/scene.splinecode" />
        </div>
      </div>
    </div>
  );
}

export default Minigame;

import "./footer.css";
import DiscordIcon from "../../assets/DiscordIcon.png";
import XIcon from "../../assets/XIcon.png";
import TelegramIcon from "../../assets/TelegramIcon.png";

function Footer() {
  return (
    <div className="footer__container">
      <div className="footer__main__elements">
        <div className="footer__inner__box">
          <div className="footer__inner__title">Join the $HOOPS Community</div>
          <div className="footer__socials">
            <div className="footer__socials__box">
              <a href="https://t.me/+BRbSXQ4TmhtiODZk" target="blank">
                <img
                  src={TelegramIcon}
                  alt="Telegram"
                  className="socials__img"
                />
              </a>

              <a href="https://twitter.com/HoopsOnChain" target="blank">
                <img src={XIcon} alt="X" className="socials__img" />
              </a>
              <a href="https://discord.gg/Fd6r8YjzAH" target="blank">
                <img src={DiscordIcon} alt="Discord" className="socials__img" />
              </a>
            </div>
          </div>
          <div className="footer__copyright__box">
            <div className="footer__copyright">
              © 2024 by Hoops DAO. Powered and secured by $HOOPS.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
